import React from "react";
import Intro from "./Intro";

const MainView = () => {
     return (
     <React.Fragment>
          <Intro />
     </React.Fragment>
     );
};

export default MainView;